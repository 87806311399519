import React from "react";
import ContactHeader from "./ContactHeader";
import Footer from "../Footer/Footer";
import "../Contact/Contact.css"
const Contact = () => {
 
  return (
    <>
    
      <ContactHeader />
      <div
        id="contact-area"
        className="contact-area bgcolor"
       
      >
        <div className="container pb-60 pt-60">
          {/* <!-- Section Title --> */}
          <div className="text-center">
              <h3 className="heading" style={{ color: "#fff" , fontFamily:"sans-serif"}} data-aos="zoom-in-up">
                CONTACT
              </h3>
              {/* <i className="icofont icofont-traffic-light"></i> */}
            </div>
          <div className="row">
           
            <div className="d-flex dflexmobi dfdestop">
            <div className="col-md-1 d-none"></div>
            <div className="col-md-2 d-none"></div>
            <div
              className="col-md-2 colcontactmob"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src="/assest/images/Contact/1.png" alt="contact1" />
            </div>
            <div
              className="col-md-2 colcontactmob"
              data-aos="flip-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src="/assest/images/Contact/2.png" alt="contact2" />
            </div>
            <div
              className="col-md-2 colcontactmob"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <img src="/assest/images/Contact/3.png" alt="contact3" />
            </div>
            <div className="col-md-2 d-none"></div>
            <div className="col-md-1 d-none"></div>
            </div>
          </div>
          <div className="text-center col-12 mb-20 pt-40">
            <h3 className="heading" style={{ color: "#fff", fontFamily:"sans-serif" }} data-aos="zoom-in">
              CONTACT US
            </h3>
            {/* <i className="icofont icofont-traffic-light"></i> */}
          </div>

          <div className="center-container">
            {/* <!-- Contact Form --> */}
            <div
              className="contact-form form text-left col-lg-8 col-sm-7 col-md-12"
            >
              <form id="contact-form" action="#" method="post">
                <div className="input-3">
                  <div className="input">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      style={{ background: "#72748b", borderRadius:"10px", opacity:0.7 }}
                      className="pl-2 ukyy"
                    />
                  </div>
                  <div className="input">
                    <input
                      type="text"
                      name="Number"
                      placeholder="Enter Phone Number"
                      style={{ background: "#72748b", borderRadius:"10px", opacity:0.7 }}
                      className="pl-2 ukyy"
                    />
                  </div>
                  <div className="input">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter E-mail"
                      style={{ background: "#72748b", borderRadius:"10px", opacity:0.7 }}
                      className="pl-2 ukyy"
                    />
                  </div>
                </div>
                <div className="input textarea">
                  <textarea
                    name="message"
                    placeholder="Type Your Message"
                    style={{ background: "#72748b", borderRadius:"10px", opacity:0.7 }}
                    className="pl-2 ukyy"
                  ></textarea>
                </div>
                <div
                  className="input input-submit"
                  style={{ textAlign: "center" }}
                >
                  <input
                    type="submit"
                    value="send message"
                    style={{ background: "#72748b", border: "solid 1px white", borderRadius:"10px", fontFamily:"sans-serif" }}
                  />
                </div>
              </form>
              <p className="form-messege"></p>
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </>
  );
};

export default Contact;
