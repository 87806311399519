import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import AOS from "aos";
import "../AllSection/HomeSec/ResponsiveWeb.css";

const Banner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds (1 second)
      delay: 200, // Delay before animation starts in milliseconds (200 milliseconds)
    });
  }, []);
  const openCalendlyPopup = () => {
    window.Calendly.initPopupWidget({
      url: "https://portal.opdapp.org",
    });
  };
  return (
    <>
      {/* <!-- Hero Area============================================ --> */}
      <div className="fix col-lx-12 col-lg-12 main-banner pb-20">
        <Header />
        <div className="container">
          <div className="hero-slide-content text-right">
            <div className="row">
              <div
                className="col-md-6 col-lx-6 col-lg-6"
                data-aos="zoom-in"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <img
                  alt="banner"
                  className="mobibanner imgshake"
                  src="/assest/images/HomeImages/Banner-vt-Correction.png"
                  style={{
                    scale: "1.4",
                  }}
                />
                {/* <img
                  alt="banner"
                  className="mobibanner"
                  src="/assest/images/OPDImagesHome/homebanner.png"
                  style={{
                    scale: "1.1",
                  }}
                /> */}
              </div>
              <div className="col-md-6 col-lx-6 col-lg-6">
                <div data-aos="zoom-out-left">
                  <h1
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                      letterSpacing: "1px",
                      fontSize: "62px",
                      fontFamily: "sans-serif",
                      marginLeft: "72px",
                      fontWeight: "700",
                    }}
                    data-aos="fade-up"
                    className="bannername typewriter"
                  >
                    Welcome to
                  </h1>

                  <h1
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                      letterSpacing: "1px",
                      fontSize: "62px",
                      fontFamily: "sans-serif",
                      marginLeft: "72px",
                      fontWeight: "700",
                    }}
                    className="bannername2"
                  >
                    The OPD
                  </h1>
                  <h1
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                      letterSpacing: "1px",
                      fontSize: "62px",
                      fontFamily: "sans-serif",
                      marginLeft: "72px",
                      fontWeight: "700",
                    }}
                    className="bannername2"
                  >
                    Technology
                  </h1>
                </div>
                <div
                  style={{
                    textAlign: "start",
                    marginLeft: "75px",
                    fontFamily: "sans-serif",
                  }}
                  className="bannername3"
                >
                
                  <p
                    className="text-white bnertextmobi"
                    style={{
                      textTransform: "capitalize",
                      textAlign: "start",
                      fontFamily: "sans-serif",
                    }}
                  >
                    OPD is a web/mobile based platform that aims to bridge service gaps by facilitating an instant telehealth connection between healthcare practitioners and patients. Our goal is to allow healthcare practitioners globally to deliver OPD and Patient Care via our SaaS-based platform.
                  </p>
                 
                </div>
                <div style={{ textAlign: "center" }}>
                  <div className="button-group btnmobi">
                    <Link
                      to="https://portal.opdapp.org/DrForm"
                      className="btn transparent"
                      style={{
                        borderRadius: "15px",
                        textTransform: "capitalize",
                        width: "150px",
                        paddingTop: "0px",
                        fontSize: "20px",
                        fontFamily: "Calibri",
                      }}
                    >
                      <b>Free Trial</b>
                    </Link>
                    <Link
                      onClick={openCalendlyPopup}
                      to=""
                      className="btn transparent"
                      style={{
                        borderRadius: "15px",
                        textTransform: "capitalize",
                        width: "150px",
                        paddingTop: "0px",
                        fontSize: "20px",
                        fontFamily: "Calibri",
                      }}
                    >
                      <b>Book A Demo</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
