import React from "react";
import "../../AllSection/HomeSec/SecEight.css";
// import Robothandpic from "../../../assets/Images/HomeImages/sec3.png"

function SecEight() {
  const bgcolor = {
    background: 'linear-gradient(to right top, #7d7f94, #10163a)',
  }
  return (
    <>
      {/* <!-- Hero Area============================================ --> */}
      <div
        className="fix fixmobi"
        style={bgcolor}
      >
        <div className="container">
          <div className="mt-5 mb-5 heromobi">
            <div className="row">
              <div className="col-md-6">
                <h2
                  style={{ color: "white", fontSize: "48px", fontFamily:"sans-serif" }}
                  data-aos="zoom-in-right"
                >
                  Experience
                  the Future of
                  <h2 style={{ color: "#32c6da", fontSize: "48px", fontFamily:"sans-serif" }} className="digitaltextmobi">
                    Digital
                    Healthcare
                  </h2>
                  Powered by
                  AI
                </h2>
                <h5 style={{ color: "white", fontFamily:"sans-serif" }} data-aos="flip-up">
                  Book an appointment online
                  with the Practitioner of your choice.
                  <br />
                  Available 24/7 on OPD
                  Healthcare Platform.
                </h5>
                <br />
                <div className="storeiconmobi">
                <img
                  alt="banner"
                  src="/assest/images/HomeImages/AppsIcon.png"
                  data-aos="fade-right"
                />
                </div>
                
              </div>
              <div className="col-md-6">
                <div  >
                  <img
                    src="./assest/images/OPDImagesHome/welcome-06.png"
                    alt="uk"
                    data-aos="zoom-in"
                    className="imgshake"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecEight;
