import React from "react";
import { Link } from "react-router-dom";
import tiktok from "../../assets/Images/HomeImages/Tiktok.png";
import './Footer.css'

const Footer = () => {
  return (
    <>
      <div
        id="contact-area"
        className="contact-area pt-20 bgcolor"
        
      >
        <div className="container pb-30">
          {/* <!-- Section Title --> */}
          <div className="hero-slide-content heromobifooter">
            <div className="row">
              <div className="col-md-3">
                <h3
                  className="mb-gap fotercentermobi"
                  style={{
                    color: "#fff",
                    textDecoration: "auto",
                    textAlign: "left",
                    fontFamily:"sans-serif"
                  }}
                >
                  Features
                </h3>
                <div className="fotercentermobi" style={{ textAlign: "start" }}>
                  <Link
                    style={{
                      color: "#fff",
                      textDecoration: "auto",
                      textAlign: "center",
                      fontFamily:"sans-serif"
                    }}
                  >
                    Importable Patient History
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Remote Patient Monitoring
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    In-App Communication
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Real-time appointment
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    VIdeo Conference
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    E-Prescription
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Ai Chatbot
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <h3
                  className="mb-gap fotercentermobi"
                  style={{
                    color: "#fff",
                    textDecoration: "auto",
                    textAlign: "left",
                    fontFamily:"sans-serif"
                  }}
                >
                  Specialities
                </h3>
                <div className="fotercentermobi" style={{ textAlign: "start" }}>
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Hair Treatment clinics
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Dermatology clinics
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Aesthetic clinics
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Eye care clinics
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Fertility clinics
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Dental clinics
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <h3
                  className="mb-gap fotercentermobi"
                  style={{
                    color: "#fff",
                    textDecoration: "auto",
                    textAlign: "left",
                    fontFamily:"sans-serif"
                  }}
                >
                  Company
                </h3>
                <div className="fotercentermobi" style={{ textAlign: "start", fontFamily:"sans-serif" }}>
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Become a Provider
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Newsletter
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Subscribe
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    About Us
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Careers
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Security
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Partner
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <h3
                  className="mb-gap fotercentermobi"
                  style={{
                    color: "#fff",
                    textDecoration: "auto",
                    textAlign: "left",
                    fontFamily:"sans-serif"
                  }}
                >
                  Services
                </h3>
                <div className="fotercentermobi" style={{ textAlign: "start" }}>
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Treatable condition
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Online Prescription
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Meet our Practitioners
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    How it works
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Contact Us
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Pricing
                  </Link>
                  <br />
                  <Link
                    to="#"
                    style={{ color: "#fff", textDecoration: "auto" }}
                  >
                    Clinics
                  </Link>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <h4 className="fotertxtsubsmobi" style={{ fontSize: "23px", color: "#fff", fontFamily:"sans-serif" }}>
              Subscribe for our Newsletter
            </h4>
            <div className="row">
              <div className="col-md-4">
                <h6 style={{ color: "white", fontFamily:"sans-serif" }}>Email Address*</h6>
                <div class="input-group mb-3">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Search"
                    style={{
                      background: "#fff",
                      color: "black",
                      height: "38px",
                    }}
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-success"
                      type="submit"
                      style={{
                        border: "solid 1px white",
                        height: "38px",
                        color: "black",
                        background: "#fff",
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
              <div className="dpmobifoter">
              <div className="col-md-2" style={{ marginTop: "12px" }}>
                <Link >
                  <img
                    alt="baner1"
                    src="/assest/images/FooterImg/App.png"
                    style={{ border: "solid 1px white", borderRadius: "10px" }}
                    // data-aos="flip-left"
                    className="foterimgmobi"
                  />
                </Link>
              </div>
              <div className="col-md-2" style={{ marginTop: "12px" }}>
                <Link >
                  <img
                    alt="baner2"
                    src="/assest/images/FooterImg/google.png"
                    style={{ border: "solid 1px white", borderRadius: "10px" }}
                    // data-aos="flip-left"
                    className="foterimgmobi"

                  />
                </Link>
              </div>
              <div className="col-md-2" style={{ marginTop: "12px" }}>
                <Link>
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/web.png"
                    style={{ border: "solid 1px white", borderRadius: "10px" }}
                    className="foterimgmobi"
                  />
                </Link>
              </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#fff" }}>
          <div className="container">
            <div className="row iconinline" style={{justifyContent:"center", flexDirection:"row"}}>
            <div className="mbrow">
                <div
                  className="col-md-5 col3mobi"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/phone.png"
                    style={{ height: "25px", margin: "10px" }}
                    className="fotericonmob"
                  />
                  <h5
                    className="fotertextmob"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    <b style={{ fontSize: "20px" }} className="textfotermobi">
                      <a href="tel:+16476606700">6476606700</a>
                    </b>
                  </h5>
                </div>

                <div className="col-md-3" style={{ display: "flex" }}>
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/envolape.png"
                    style={{ height: "25px", margin: "10px" }}
                    className="fotericonmob"
                  />
                  <h5
                    style={{
                      marginTop: "10px",
                      fontSize: "15px",
                      fontFamily: "sans-serif",
                    }}
                    className="fotertextmob"
                  >
                    <b style={{ fontSize: "20px" }} className="textfotermobi">
                      <a href="mailto:info@opd.ca">
                      info@opd.ca
                      </a>
                    </b>
                  </h5>
                </div>
              </div>
              <div className="col-md-2"></div>

              <div className="col-md-3" style={{ display: "flex" }}>
                <Link>
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/intgram.png"
                    style={{
                      height: "35px",
                      border: "solid 2px black",
                      borderRadius: "10px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  />
                </Link>
                <Link
                
                >
                  <img
                    alt="baner"
                    src={tiktok}
                    style={{
                      height: "35px",
                      border: "solid 2px black",
                      borderRadius: "10px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  />
                </Link>
                <Link >
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/pinterest.png"
                    style={{
                      height: "35px",
                      border: "solid 2px black",
                      borderRadius: "10px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  />
                </Link>
                <Link
            
                >
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/fb.png"
                    style={{
                      height: "35px",
                      border: "solid 2px black",
                      borderRadius: "10px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  />
                </Link>
                <Link >
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/twitter.png"
                    style={{
                      height: "35px",
                      border: "solid 2px black",
                      borderRadius: "10px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  />
                </Link>
                <Link>
                  <img
                    alt="baner"
                    src="/assest/images/FooterImg/linkedin.png"
                    style={{
                      height: "35px",
                      border: "solid 2px black",
                      borderRadius: "10px",
                      padding: "5px",
                      margin: "5px",
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
